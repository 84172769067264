<template>
  <div>
    <v-sheet class="mx-auto" style="background-color: transparent">
      <v-slide-group center-active show-arrows class="tab">
        <v-slide-item
          v-for="(tab, index) in listTab"
          :key="'tab-' + tab.tabValue"
          :ref="tab.tabValue"
          v-slot="{ toggle }"
        >
          <div>
            <v-btn
              small
              class="tab-item-btn mx-2"
              depressed
              rounded
              :class="{ active: tabActive.tabValue === tab.tabValue }"
              @click="onChangeTab(tab)"
              @hook:mounted="storeTabActive(tab.tabValue, toggle)"
            >
              {{ tab.tabName }}
              <span class="ml-2" @click.stop="deleteTab(tab, index)">
                <v-icon color="#fff" size="20">mdi-close-circle-outline</v-icon>
              </span>
            </v-btn>
          </div>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
    <router-view
      ref="childrenComponent"
      :key="$route.fullPath"
      @tabChanged="handleTabChanged"
    />
  </div>
</template>

<script>
import TabCarousel from "@/components/commonComponents/TabCarousel.vue";
import Index from "@/views/vehicle-acquisitions-stock/edit/Index.vue";
import localStorageTabs from "@/mixins/multiple-tab/localStorageTabs";
const routeList = [
  {
    currentRouteName: "VehicleAcquisitionsDetails",
    key: "tabs-va",
    backToRouteName: "VehicleAcquisitionsList",
  },
  {
    currentRouteName: "VehicleAcquisitionsStockDetails",
    key: "tabs-vehicle",
    backToRouteName: "VehicleAcquisitionsStockList",
  },
  {
    currentRouteName: "InvoiceDetails",
    key: "tabs-invoice",
    backToRouteName: "InvoiceList",
  },
  {
    currentRouteName: "PurchaseOrderDetail",
    key: "tabs-po",
    backToRouteName: "Purchaselist",
  },
];
export default {
  name: "LayoutTabs",
  components: { TabCarousel, Index },
  mixins: [localStorageTabs],
  data() {
    return {
      tabActive: {
        tabValue: "",
        tabName: "",
        item: {},
      },
      listTab: [],
      toggles: {},
    };
  },
  watch: {
    $route(to, from) {
      this.initialization();
    },
  },
  methods: {
    initialization() {
      this.listTab = JSON.parse(
        localStorage.getItem(this.checkRouteName().key)
      );
      const itemId = this.$route.params.id;
      this.tabActive.tabValue = Number(itemId);
    },
    handleTabChanged(value) {
      this.pushItemToLocalStorageArray(value, this.checkRouteName().key);
      this.listTab = JSON.parse(
        localStorage.getItem(this.checkRouteName().key)
      );

      this.tabActive = value;

      this.$nextTick(() => {
        this.activateScrollTab(value.tabValue);
      });
    },
    async onChangeTab(tabItem) {
      this.tabActive = tabItem;
      await this.$router.push({ params: { id: tabItem.tabValue } });
    },
    deleteTab(tabItem, index) {
      const removedTabWidth =
        this.$refs[tabItem.tabValue]?.[0].$el?.offsetWidth || 0;

      // const lastItem = this.listTab.length - 1;
      this.listTab.splice(index, 1);
      this.removeItemFromLocalStorageArray(
        (item) => item.tabValue === tabItem.tabValue,
        this.checkRouteName().key
      );
      this.checkUpdateSlide(removedTabWidth);
      this.checkNavigateDelete(tabItem, index);
    },
    checkUpdateSlide(removedTabWidth) {
      this.$nextTick(() => {
        const slideContent = this.$el.querySelector(".v-slide-group__content");
        if (slideContent) {
          const transformValue = slideContent.style.transform;
          const currentTransformX = transformValue
            ? parseFloat(
                transformValue.replace("translateX(", "").replace("px)", ""),
                10
              )
            : 0;
          if (currentTransformX < -removedTabWidth) {
            slideContent.style.transform = `translateX(${
              currentTransformX + removedTabWidth
            }px)`;
          } else {
            slideContent.style.transform = `translateX(0px)`;
          }
        }
      });
    },
    checkNavigateDelete(tabItem, index) {
      const lastItem = this.listTab.length;
      if (this.listTab.length === 0) {
        this.$router.push({ name: this.checkRouteName().backToRouteName });
        return;
      }
      if (this.tabActive.tabValue === tabItem.tabValue) {
        if (index === 0) {
          this.tabActive.tabValue = this.listTab[0].tabValue;
        } else if (index === lastItem) {
          this.tabActive.tabValue = this.listTab[index - 1].tabValue;
        } else {
          this.tabActive.tabValue = this.listTab[index].tabValue;
        }
        this.$router.push({ params: { id: this.tabActive.tabValue } });
      }
    },
    checkRouteName() {
      for (let i = 0; i < routeList.length; i++) {
        if (this.$route.name === routeList[i].currentRouteName) {
          return {
            key: routeList[i].key,
            backToRouteName: routeList[i].backToRouteName,
          };
        }
      }
    },
    updateListTabs(e) {
      const currentTab = this.checkRouteName();
      if (e.key === currentTab.key) {
        const newVal = JSON.parse(e.newValue);
        const oldVal = JSON.parse(e.oldValue);

        this.listTab = newVal;
        // update if delete tab
        if (oldVal && oldVal.length > newVal.length) {
          const mapValue = new Map(
            newVal.map((item, index) => [item.tabValue, index])
          );

          const removedItems = oldVal
            .map((oldItem, index) => {
              if (!mapValue.has(oldItem.tabValue)) {
                return { item: oldItem, index };
              }
              return null;
            })
            .filter((result) => result !== null);

          const { item, index } = removedItems[0];

          this.checkNavigateDelete(item, index);
        }
      }
    },
  },
  created() {
    this.initialization();
  },
  async mounted() {
    window.addEventListener("storage", this.updateListTabs);
  },
  destroyed() {
    window.removeEventListener("storage", this.updateListTabs);
  },
};
</script>

<style scoped lang="scss">
// .tabs {
//   display: flex;
//   column-gap: 12px;
//   margin-bottom: 12px;
// }

// .tabs .tab-item {
//   padding: 10px 20px;
//   cursor: pointer;
//   background-color: #9b9fa7a6;
//   border: none;
//   border-bottom: 2px solid transparent;
//   font-size: 12px;
//   color: #ffffff99;
// }
// .tabs .tab-item.active {
//   //border-bottom: 2px solid #f5f5f5;
//   background: #1e88e5;
//   color: #ffffff;
// }
::v-deep .tab .tab-item-btn {
  background-color: #9b9fa7a6 !important;
  color: #ffffff99;
}
::v-deep .tab .tab-item-btn.active {
  background: #1e88e5 !important;
  color: #ffffff;
}
::v-deep .tab .v-slide-group__prev .v-icon {
  font-size: 40px;
}
::v-deep .tab .v-slide-group__next .v-icon {
  font-size: 40px;
}
</style>
