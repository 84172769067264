export default {
  data() {
    return {
      //ref
      tabRef: {},
    };
  },
  methods: {
    registerTab(tabValue, tabName) {
      this.tabRef = {
        tabValue: tabValue,
        tabName: tabName,
      };
      this.$emit("tabChanged", this.tabRef);
    },
  },
};
