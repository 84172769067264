import { debounce } from "../../utils/debounce";
import {
  onMakeChange,
  onModelChange,
  onSeriesChange,
  handleApiError,
} from "@/utils/common";

export default {
  data() {
    return {
      locationGroupOptions: [
        { locGroupDesc: "N/A", locGroupID: 0 },
        { locGroupDesc: "Discard", locGroupID: 2006 },
        { locGroupDesc: "Keep", locGroupID: 2005 },
      ],
      referredOptions: [],
      driverOptions: [],
      countryOption: [
        {
          id: 1,
          name: "New Zealand",
        },
      ],

      location: {
        isLocationloading: false,
        locationOptions: [],
      },

      supplierSearch: {
        contactName: "",
        debtorType: 0,
        pageNum: 1,
        pageSize: 10,
      },
      supplierMenu: {
        supplierOptions: [],
        totalPage: 1,
        isLoading: false,
      },

      disposalDebtorSearch: {
        contactName: "",
        debtorType: 0,
        pageNum: 1,
        pageSize: 10,
      },
      disposalDebtorMenu: {
        disposalDebtorList: [],
        totalPage: 1,
        isLoading: false,
      },
      getVehicleOptions: true,
      getPurchaseOptions: true,

      request: null,
      // to manage quere
      watcherQueue: Promise.resolve(),
    };
  },
  mounted() {
    if (this.getVehicleOptions) {
      this.getMakeOptions();
      this.getModelTypeOptions();
      this.getYearOptions();
      this.getPartTypeOptions();
      this.getColorOptions();
      this.getTrimColourOptions();
    }
    // this.getLocationGroupOptions()
    this.getDriverOptions();
    if (this.getPurchaseOptions) {
      this.getReferredOptions();
      this.getExpenseTypeOptions();
      this.getPaymentTypeOptions();
      this.getStockOptions();
      this.getStockPurchaseTypeOptions();
      this.getWriteOffOptions();
      this.getPlateOptions();
    }
  },
  methods: {
    async getLocationGroupOptions() {
      try {
        const result =
          await this.$api.vehicleAcquisition.getLocationGroupList();

        const { code, data } = result;
        if (code === 200) {
          this.locationGroupOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getReferredOptions() {
      try {
        const result = await this.$api.common.getReferredList();

        const { code, data } = result;
        if (code === 200) {
          this.referredOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    async getDriverOptions() {
      try {
        const result = await this.$api.common.getDriverList();

        const { code, data } = result;
        if (code === 200) {
          this.driverOptions = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    supplierChangeHandler(value) {
      const selectedPeople = this.supplierMenu.supplierOptions.find(
        (res) => res.debtorId === value
      );
      // const obj = {
      //   firstName: selectedPeople.contactName,
      //   surName: selectedPeople.contactSurname,
      //   pickUpAddress: selectedPeople.address,
      //   suburb: selectedPeople.suburb,
      //   state: selectedPeople.state,
      //   postCode: selectedPeople.postCode,
      //   pickUpCountry: 1,
      //   ownerFirstName: selectedPeople.contactName,
      //   ownerSurName: selectedPeople.contactSurname,
      //   ownerAddress: selectedPeople.address,
      //   ownerSuburb: selectedPeople.suburb,
      //   ownerState: selectedPeople.state,
      //   ownerPostcode: selectedPeople.postCode,
      //   mobile: selectedPeople.mobile,
      //   ownerCountry: 1,
      //   licenseNo: selectedPeople.licenseNo,
      //   phone: selectedPeople.phone1
      // }
      // this.$set(this.form, "purchaseDetail", obj)
      // Purchased from and pickup
      this.form.purchaseDetail.contactId = selectedPeople.contactID;
      this.form.purchaseDetail.purchasedFrom = selectedPeople.company;
      this.form.purchaseDetail.firstName = selectedPeople.contactName;
      this.form.purchaseDetail.surName = selectedPeople.contactSurname;
      this.form.purchaseDetail.purchasePhone = selectedPeople.phone1;
      this.form.purchaseDetail.purchaseMobile = selectedPeople.mobile;
      this.form.purchaseDetail.pickUpAddress = selectedPeople.address;
      this.form.purchaseDetail.suburb = selectedPeople.suburb;
      this.form.purchaseDetail.state = selectedPeople.state;
      this.form.purchaseDetail.postCode = selectedPeople.postCode;

      // Owner infomation
      this.form.purchaseDetail.ownerContactId = selectedPeople.contactID;
      this.form.purchaseDetail.ownerFirstName = selectedPeople.contactName;
      this.form.purchaseDetail.ownerSurName = selectedPeople.contactSurname;
      this.form.purchaseDetail.phone = selectedPeople.phone1;
      this.form.purchaseDetail.mobile = selectedPeople.mobile;
      this.form.purchaseDetail.ownerEmail = selectedPeople.email;
      this.form.purchaseDetail.ownerAddress = selectedPeople.address;
      this.form.purchaseDetail.ownerSuburb = selectedPeople.suburb;
      this.form.purchaseDetail.ownerState = selectedPeople.state;
      this.form.purchaseDetail.ownerPostcode = selectedPeople.postCode;
      this.form.purchaseDetail.licenseNo = selectedPeople.licenseNo;
    },

    async getSuppliersOptions() {
      await this.fetchOptions(
        this.supplierMenu,
        this.supplierSearch,
        this.$api.common.getContactList
      );
    },

    async fetchOptions(menu, search, apiMethod) {
      if (menu.isLoading || search.contactName === "") return;

      menu.isLoading = true;
      if (this.request) {
        this.request.abort();
      }
      this.request = new AbortController();
      const signal = this.request.signal;

      try {
        const payload = {
          contactName: search.contactName,
          // debtorType: search.debtorType,
          pageNum: search.pageNum,
          pageSize: search.pageSize,
          enabled: 1,
        };
        const result = await apiMethod(payload, signal);
        const { code, data } = result;
        if (code === 200) {
          menu.isLoading = false;
          menu.totalPage = data.pages;
          menu.supplierOptions
            ? menu.supplierOptions.push(...data.records)
            : menu.disposalDebtorList.push(...data.records);
        }
      } catch (err) {
        if (err.name !== "CanceledError") {
          menu.isLoading = false;
          handleApiError(err, this.showNotification);
        }
      }
    },

    async searchHandler(event, menu, search, apiMethod) {
      const { value } = event.target;
      menu.supplierOptions
        ? (menu.supplierOptions = [])
        : (menu.disposalDebtorList = []);
      search.contactName = value.trim();

      if (search.contactName === "") {
        menu.supplierOptions
          ? (this.form.purchaseDetail.debtorId = 0)
          : (this.form.option.disposalDebtorId = 0);
        return;
      }
      search.pageNum = 1;
      this.fetchOptions(menu, search, apiMethod);
    },

    supplierSearchHandler: debounce(function (event) {
      this.searchHandler(
        event,
        this.supplierMenu,
        this.supplierSearch,
        this.$api.common.getContactList
      );
    }, 500),

    disposalDebtorSearchHandler: debounce(function (event) {
      this.searchHandler(
        event,
        this.disposalDebtorMenu,
        this.disposalDebtorSearch,
        this.$api.common.getContactList
      );
    }, 500),

    async handleMenuScroll(e, menu, search, apiMethod) {
      if (
        e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) >
          80 ||
        search.pageNum >= menu.totalPage ||
        menu.isLoading
      )
        return;

      search.pageNum += 1;
      await this.fetchOptions(menu, search, apiMethod);
    },

    handleSupplierMenuScroll(e) {
      this.handleMenuScroll(
        e,
        this.supplierMenu,
        this.supplierSearch,
        this.$api.common.getContactList
      );
    },

    handleDisposalDebtorMenuScroll(e) {
      this.handleMenuScroll(
        e,
        this.disposalDebtorMenu,
        this.disposalDebtorSearch,
        this.$api.common.getContactList
      );
    },

    locationSearchHandler: debounce(async function (event) {
      const { value } = event.target;
      this.getLocationOptions(value);
    }),
    async getLocationOptions(search) {
      this.location.isLocationloading = true;
      try {
        const result = await this.$api.common.getLocationList({
          locationCode: search,
          pageNum: 1,
          pageSize: 100,
        });
        const { code, data } = result;
        if (code === 200) {
          this.location.locationOptions = data.records;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.location.isLocationloading = false;
      }
    },

    async handleMakeIdChange(newValue, oldValue) {
      this.form.makeDesc = onMakeChange(newValue, this.makeOptions);
      this.modelOptions = [];
      await this.getModelOptions(newValue);

      if (oldValue) {
        this.form.modelId = "";
        this.form.engineDesc = "";
        this.form.engineId = "";
        this.form.transmission = "";
        this.form.transId = "";
      }

      this.getEngineOptions(newValue);
      this.getTransimissionOptions(newValue);
      if (this.form.model) {
        this.form.modelId = this.modelOptions.find(
          (res) => res.name.toLowerCase() === this.form.model.toLowerCase()
        )?.id;
      }
    },
    async handleModelIdChange(newValue, oldValue) {
      if (oldValue) {
        this.seriesOptions = [];
        this.form.seriesId = "";
      }
      if (newValue) {
        this.form.model = onModelChange(newValue, this.modelOptions);
        await this.getSeriesOptions(this.form.makeId, newValue);
      } else {
        this.form.model = "";
      }
    },
    async handleSeriesIdChange(newValue) {
      if (newValue) {
        this.form.seriesDesc = onSeriesChange(newValue, this.seriesOptions);
      } else {
        this.form.seriesDesc = "";
      }
    },
  },
  watch: {
    "form.makeId": {
      async handler(newValue, oldValue) {
        if (this.disableWatchOptions) return;
        this.watcherQueue = this.watcherQueue.then(() =>
          this.handleMakeIdChange(newValue, oldValue)
        );
      },
      deep: true,
    },
    "form.modelId": {
      handler(newValue, oldValue) {
        if (this.disableWatchOptions) return;
        this.watcherQueue = this.watcherQueue.then(() =>
          this.handleModelIdChange(newValue, oldValue)
        );
      },
      deep: true,
    },
    "form.seriesId": {
      handler(newValue) {
        if (this.disableWatchOptions) return;
        this.watcherQueue = this.watcherQueue.then(() =>
          this.handleSeriesIdChange(newValue)
        );
      },
    },
  },
};
