export const HTTP_CODE_SUCCESS = 200;
export const typeScreen = Object.freeze({
  tagNo: "tagLocator",
  stockDetail: "stockDetail",
});

export const VA_STATUSES = {
  OPEN: { key: "Open", value: 1 },
  CLOSED: { key: "Closed", value: 2 },
  CANCELLED: { key: "Cancelled", value: 3 },
  IN_PROGRESS: { key: "In Progress", value: 4 },
  COMPLETED: { key: "Completed", value: 5 },
};

export const invoiceStatusEnum = {
  open: 1,
  closed: 3,
  cancelled: 4,
  finalized: 5,
  "In Progress": 6,
  completed: 7,
  confirmed: 8,
};

export const INVOICE_STATUSES = {
  OPEN: { key: "Open", value: 1 },
  CLOSED: { key: "Closed", value: 3 },
  CANCELLED: { key: "Cancelled", value: 4 },
  FINALIZED: { key: "Finalized", value: [2, 5] },
  IN_PROGRESS: { key: "In Progress", value: 6 },
  COMPLETED: { key: "Completed", value: 7 },
  CONFIRMED: { key: "Confirmed", value: 8 },
};

export const DISPATCH_STATUSES = {
  OPEN: { key: "Open", value: 1 },
  IN_PROGRESS: { key: "In Progress", value: 2 },
  COMPLETED: { key: "Completed", value: 3 },
};

export const DELIVERY_STATUSES = {
  OPEN: { key: "Open", value: 1 },
  IN_PROGRESS: { key: "Out for Delivery", value: 2 },
  DELIVERED: { key: "Delivered", value: 3 },
};

export const TRANSACTION_TYPES = {
  ACQ: "ACQ",
  INVOICE: "INV",
  QUOTE: "Q",
  PURCHASE_ORDER: "PO",
};

export const ORDER_BY = {
  ASC: "ASC",
  DESC: "DESC",
};

export const DATE_FORMAT = "DD-MM-YYYY";
export const DATE_TIME_FORMAT = "DD-MM-YYYY HH:mm:ss";

export const ROLES = {
  SUPER_ADMIN: "SUPER ADMIN",
  SALES_PERSON: "SALES PERSON",
  ACCOUNTING: "ACCOUNTING",
  BUYING_DEPARTMENT: "BUYING DEPARTMENT",
  INVENTORY_PERSON: "INVENTORY PERSON",
  DISPATCHER: "DISPATCHER",
  DRIVER: "DRIVER",
  PICKER: "PICKER",
  PICKING_SUPERVISOR: "PICKING SUPERVISOR",
  DISPATCH_SUPERVISOR: "DISPATCH SUPERVISOR",
  CAR_SUPERVISOR: "CAR_SUPERVISOR",
};

export const NON_REFRESH_ROUTES = ["InventoryList"];
