function createEventHandler(el, processFunction) {
  el._inputHandler = () => {
    const transformedValue = processFunction(el.value);
    if (el.value !== transformedValue) {
      el.value = transformedValue;
      el.dispatchEvent(new Event("input"));
    }
  };
  el.addEventListener("input", el._inputHandler);
}

function removeEventHandler(el) {
  if (el._inputHandler) {
    el.removeEventListener("input", el._inputHandler);
    delete el._inputHandler;
  }
}
function getEl(el) {
  const input = el.tagName === "INPUT" ? el : el.querySelector("input");
  const textarea =
    el.tagName === "TEXTAREA" ? el : el.querySelector("textarea");
  return input ?? textarea;
}
function createDirective(callback) {
  return {
    inserted(el) {
      const elTarget = getEl(el);
      if (elTarget) createEventHandler(elTarget, callback);
    },
    unbind(el) {
      const elTarget = getEl(el);
      if (elTarget) removeEventHandler(elTarget);
    },
  };
}

export const uppercaseAll = createDirective((value) => value.toUpperCase());
export const capitalizeFirst = createDirective(
  (value) => value.charAt(0).toUpperCase() + value.slice(1)
);
